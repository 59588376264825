import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"

// @Mui components
import { styled } from "@mui/material/styles"

//Other components
import Axios from "axios"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import dayjs from "dayjs"

// Core components
import { useDialog } from "context/DialogContext"
import GridContainer from "components/material-dashboard-pro-react/components/Grid/GridContainer"
import GridItem from "components/material-dashboard-pro-react/components/Grid/GridItem"
import { getIdentification, indentificationTypeAll } from "utils/utils"
import Identification from "components/Core/Controller/Identification"
import SelectSimpleController from "components/Core/Controller/SelectSimpleController"
import EmailController from "components/Core/Controller/EmailController"
import PhoneController from "components/Core/Controller/PhoneController"
import { useLoading } from "context/LoadingContext"
import { initAxiosInterceptors } from "utils/axiosConfig"
import DateMaterialPickerController from "components/Core/Controller/DateMaterialPickerController"
import registerStyles from "./registerStyle"

const NewDiv = styled("div")(({ theme }) => {
  return { ...registerStyles.tCenter }
})

const NewReCAPTCHA = styled(ReCAPTCHA)(({ theme }) => {
  return { ...registerStyles.gRecaptcha }
})

const RegisterIdentification = forwardRef((props, ref) => {
  //const { triggerValidation, getValues, ...objForm } = useForm();
  const defaultValues = {
    p_profile_id: "",
    p_identification_type_1: "",
    p_identification_number_1: "",
    p_birthdate: "",
    p_email: "",
    p_phone_ini: "",
  }
  const methods = useForm({ defaultValues })
  const {
    //handleSubmit,
    control,
    //reset,
    trigger,
    ...objForm
  } = methods

  const { reset, getValues } = objForm

  const [profiles, setProfiles] = useState([])

  const [profileSelected, setProfileSelected] = useState([])

  const [state, setstate] = useState()
  const [showBirthDay, setShowBirthDay] = useState(false)
  const [showEmailInput, setShowEmailInput] = useState(false)
  const [showPhoneNumberConfirm, setShowPhoneNumberConfirm] = useState(false)

  const [emailFromCore, setEmailFromCore] = useState(null)
  const [cellPhoneFromCore, setCellPhoneFromCore] = useState(null)

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
  const [captchaKey, setCaptchaKey] = useState()
  const dialog = useDialog()
  const loading = useLoading()
  let captcha

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref)
    }
  }

  async function getProfiles() {
    try {
      const jsonProfiles = await Axios.post(
        `${process.env.GATSBY_API_URL}/asg-api/dbo/security/get_profiles_for_register`
      )
      setProfiles(jsonProfiles.data.p_results)
    } catch (error) {
      console.log(error)
    }
  }

  async function getCaptchaKey() {
    try {
      const jsonKey = await Axios.post(
        `${process.env.GATSBY_API_URL}/asg-api/dbo/security/get_captcha_key`
      )
      if (jsonKey && jsonKey.data && jsonKey.data.result) {
        setCaptchaKey(jsonKey.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCaptchaKey()
  }, [])

  useEffect(() => {
    getProfiles()
  }, [])

  useEffect(() => {
    initAxiosInterceptors(dialog, loading)
  }, [])

  const handleShowInsuredInfo = (value) => {
    const showByValue = value == 6 ? true : false
    setShowBirthDay(showByValue)
    setShowEmailInput(showByValue)
    setShowPhoneNumberConfirm(showByValue)
    setProfileSelected(value)
  }

  async function getContactInfo() {
    try {
      if (profileSelected && profileSelected === 6) {
        const values = getValues()
        const [numid, dvid] = getIdentification(
          values.p_identification_type_1,
          values.p_identification_number_1
        )
        const params = {
          p_json_params: JSON.stringify({
            p_tipoid: values.p_identification_type_1,
            p_numid: numid,
            p_dvid: dvid + "",
          }),
        }

        setShowEmailInput(false)
        setShowPhoneNumberConfirm(false)

        const { data } = await Axios.post(
          `/dbo/security/get_contact_info4register`,
          params
        )
        if (data.result.length > 0) {
          const infoContactPerson = data.result[0]
          setEmailFromCore(infoContactPerson.EMAIL)

          const codigo = infoContactPerson.CODAREA3 || ""
          const numero = infoContactPerson.TELEF3 || ""
          const numberValue = codigo + numero

          setCellPhoneFromCore(numberValue)

          setShowEmailInput(true)
          setShowPhoneNumberConfirm(true)
          const newData = {
            ...values,
          }
          newData.p_email = infoContactPerson.EMAIL
          newData.p_phone_ini = numberValue
          reset({ ...newData })
          /*const stateNew = {...state}
                  stateNew.email = infoContactPerson.EMAIL
                  stateNew.phonenumber = numberValue
                  setstate(stateNew)*/
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  function onChangeCaptcha(value) {
    setIsCaptchaVerified(value ? true : false)
  }

  async function getUser(data, postFnc) {
    try {
      //if (isCaptchaVerified) {
      const [numid, dvid] = getIdentification(
        data.p_identification_type_1,
        data.p_identification_number_1
      )
      const params = {
        p_profile_reg_id: data.p_profile_id,
        p_json_params: JSON.stringify({
          tipoid: data.p_identification_type_1,
          numid: numid,
          dvid: dvid,
          fecnac: dayjs(data.p_birthdate).format("DD/MM/YYYY"),
          email: data.p_email,
          phonenumber: data.p_phone_ini,
        }),
      }
      const response = await Axios.post(
        `${process.env.GATSBY_API_URL}/asg-api/dbo/security/get_user_for_register`,
        params
      )
      setstate({
        profile_id: data.p_profile_id,
        tipoid: data.p_identification_type_1,
        numid: numid,
        dvid: dvid,
        ...response.data.p_results,
      })

      postFnc({
        profile_id: data.p_profile_id,
        tipoid: data.p_identification_type_1,
        numid: numid,
        dvid: dvid,
        ...response.data.p_results,
      })
      //  } else {
      // captcha.reset();
      // }
    } catch (error) {
      console.log(error)
    }
  }

  useImperativeHandle(ref, () => ({
    async isValidated(postValidate) {
      const result = await trigger()
      if (result) {
        const values = getValues()
        await getUser(values, postValidate)
      }
    },
    sendState() {
      return state
    },
  }))

  return (
    <GridContainer justify="center">
      {captchaKey && (
        <GridItem xs={12} sm={8}>
          <form noValidate>
            <SelectSimpleController
              control={control}
              label="Perfil"
              name="p_profile_id"
              array={profiles}
              onChange={handleShowInsuredInfo}
            />
            <Identification
              objForm={objForm}
              control={control}
              index={1}
              arrayType={indentificationTypeAll}
              onBlur={getContactInfo}
            />
            {showBirthDay && (
              <DateMaterialPickerController
                control={control}
                label="Fecha de Nacimiento"
                name={`p_birthdate`}
                disableFuture
              />
            )}
            {showEmailInput && (
              <EmailController
                control={control}
                label="Correo Electrónico"
                name={`p_email`}
                defaultValue={emailFromCore}
              />
            )}
            {showPhoneNumberConfirm && (
              <PhoneController
                control={control}
                label="Número de Teléfono"
                name={`p_phone_ini`}
                defaultValue={cellPhoneFromCore}
              />
            )}
            <br></br>
            <br></br>
            {/*<NewDiv>
                            <NewReCAPTCHA
                                ref={(r) => setCaptchaRef(r)}
                                sitekey={captchaKey}
                                onChange={onChangeCaptcha}
                            />
            </NewDiv>*/}
          </form>
        </GridItem>
      )}
    </GridContainer>
  )
})
export default RegisterIdentification
